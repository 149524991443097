.App {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.input-group, .section {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-group h3, .section h3 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
}

.input-group input, .select-input {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  transition: all 0.3s ease;
}

.input-group input:focus, .select-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

button {
  padding: 12px 18px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.fetch-button, .command-button, .check-button {
  display: block;
  margin-top: 10px;
}

.table-container {
  margin-top: 20px;
}

.data-table, .check-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th, .check-table th, .data-table td, .check-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.data-table th, .check-table th {
  background-color: #f4f4f4;
}

.message {
  color: green;
  font-weight: bold;
}
